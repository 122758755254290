import * as React from "react"
import styled from "styled-components"

import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const Paddinged = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;

  color: #003d6a;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Paddinged>
      <Row>
        <Col>
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist. 😔</p>
        </Col>
      </Row>
    </Paddinged>
  </Layout>
)

export default NotFoundPage
